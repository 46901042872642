<template>
  <div class="vac-room-overcontainer">
    <div class="vac-room-container">
      <slot :name="'room-list-item_' + room.roomId">
        <slot :name="'room-list-avatar_' + room.roomId">
          <div
            v-if="room.avatar"
            class="vac-avatar"
            :style="{ 'background-image': `url('${room.avatar}')` }"
          />
          <div v-else>
            <v-sheet
              elevation="1"
              height="35"
              width="35"
              :class="{'theme-second-background-color': !isClosedConversation, 'grey darken-3': isClosedConversation}"
              class="mr-1 nametag "
            >
              <span
                v-if="!startsWithEmoji && contactLetters.length"
                class="avatar-letter"
              >
                {{ contactLetters }}
              </span>
              <span v-else>
                <v-icon class="avatar-letter">
                  fas fa-user
                </v-icon>
              </span>
            </v-sheet>
          </div>
        </slot>
        <div class="vac-name-container vac-text-ellipsis">
          <div class="vac-title-container">
            <!-- <div
              v-if="userStatus"
              class="vac-state-circle"
              :class="{ 'vac-state-online': userStatus === 'online' }"
            /> -->
            <div class="vac-room-name vac-text-ellipsis ml-2">
              {{ roomName }}
            </div>
            <div
              v-if="room.lastMessage"
              class="vac-text-date"
            >
              {{ room.lastMessage.timestamp | dateLocal('DD/MM HH:mm') }}
            </div>
          </div>
          <div
            class="vac-text-last"
            :class="{
              'vac-message-new':
                room.lastMessage && room.lastMessage.new && !typingUsers
            }"
          >
            <span v-if="isMessageCheckmarkVisible">
              <slot :name="'checkmark-icon_' + room.roomId">
                <svg-icon
                  :name="
                    room.lastMessage.distributed
                      ? 'double-checkmark'
                      : 'checkmark'
                  "
                  :param="room.lastMessage.seen ? 'seen' : ''"
                  class="vac-icon-check"
                />
              </slot>
            </span>


            <div
              v-if="room.lastMessage && !room.lastMessage.deleted && isAudio"
              class="vac-text-ellipsis"
            >
              <slot :name="'microphone-icon_' + room.roomId">
                <svg-icon
                  name="microphone"
                  class="vac-icon-microphone"
                />
              </slot>
              {{ formattedDuration }}
            </div>
            <span
              v-if="isClosed"
              class="ml-2"
            >
              <v-icon class="pr-2">
                fas fa-box-archive
              </v-icon>
              <span style="display: contents;">{{ room.lastMessage.content }}</span>
            </span>
            <span
              v-if="isOpen"
              class="ml-2"
            >
              <v-icon class="pr-2">
                fas fa-box-archive
              </v-icon>
              <span style="display: contents;">{{ room.lastMessage.content }}</span>
            </span>
            <span
              v-if="isEdited"
              class="ml-2"
            >
              <v-icon class="pr-2">
                fas fa-pencil
              </v-icon>
              <span style="display: contents;">{{ room.lastMessage.content }} </span>
            </span>
            <span
              v-if="isBlocked"
              class="ml-2"
            >
              <v-icon
                class="pr-2"
              >
                {{ room.lastMessage.blockStatus === 1 ? 'fas fa-user-slash' : 'fas fa-user' }}
              </v-icon>
              <span
                v-if="room.lastMessage.blockType === 1"
                style="display: contents;"
              >
                {{ $t('Utilidad') }}
              </span>
              <span
                v-if="room.lastMessage.blockType === 2"
                style="display: contents;"
              >
                {{ $t('Marketing') }}
              </span>
              <span
                v-if="room.lastMessage.blockType === 3"
                style="display: contents;"
              >
                {{ $t('Autenticación') }}
              </span>
              <span
                v-if="room.lastMessage.blockType === 4"
                style="display: contents;"
              >
                {{ $t('Inbox') }}
              </span>
            </span>
            <span v-if="isChangeAgent">
              <v-icon
                v-if="room.lastMessage.agentTypeId === 0"
                class="theme-primary-color float-left mr-3 ml-1"
                size="15"
              >
                fas fa-user
              </v-icon>
              <v-img
                v-else
                :src="require('@/assets/img/nia/nia.png')"
                max-width="18px"
                class="ml-1 mr-2 float-left"
              />
              <span>
                {{ room.lastMessage.agent }}
              </span>
            </span>
            <div v-else-if="room.lastMessage.content === ''">
              <span
                v-if="room.lastMessage.type === 'image'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-image
                </v-icon>
                <span style="display: contents;">{{ $t('Imagen') }}</span>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'video'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-video
                </v-icon>
                <span style="display: contents;">{{ $t('Video') }}</span>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'audio'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-microphone
                </v-icon>
                <span style="display: contents;">{{ $t('Audio') }}</span>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'template'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  far fa-file-lines
                </v-icon>
                <p style="display: contents;">{{ $t('Plantilla') }}</p>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'document'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  far fa-file
                </v-icon>
                <p style="display: contents;">{{ $t('Documento') }}</p>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'location'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-location-dot
                </v-icon>
                <span> {{ $t('Localización') }}</span>
              </span>

              <span
                v-else-if="room.lastMessage.type === 'interactive'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-location-dot
                </v-icon>
                <span style="display: contents;">{{ $t('Interactivo') }}</span>
              </span>
              <span
                v-else-if="room.lastMessage.type === 'contacts'"
                class="ml-2"
              >
                <v-icon class="pr-2">
                  fas fa-users
                </v-icon>
                <span style="display: contents;">{{ $t('Contactos') }}</span>
              </span>
            </div>
            <format-message
              v-else-if="room.lastMessage && isMessage"
              :message-id="room.lastMessage._id"
              :room-id="''+room.roomId"
              :room-list="true"
              :content="getLastMessageNoNewLines"
              :deleted="!!room.lastMessage.deleted && !typingUsers"
              :users="room.users"
              :text-messages="textMessages"
              :linkify="false"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :single-line="true"
              class="ml-2"
            >
              <template
                v-for="(idx, name) in $slots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </format-message>
            <div
              v-if="!room.lastMessage && typingUsers"
              class="vac-text-ellipsis"
            >
              {{ typingUsers }}
            </div>
            <div class="vac-room-options-container">
              <div
                v-if="isContactUnread(room)"
                class="vac-badge-counter vac-room-badge theme-second-background-color white--text"
              >
                {{ room.unreadCount > 0 ? room.unreadCount : '' }}
              </div>
              <slot :name="'room-list-options_' + room.roomId">
                <div
                  v-if="roomActions.length"
                  class="vac-svg-button vac-list-room-options"
                  @click.stop="roomMenuOpened = room.roomId"
                >
                  <slot :name="'room-list-options-icon_' + room.roomId">
                    <svg-icon
                      name="dropdown"
                      param="room"
                    />
                  </slot>
                </div>
                <transition
                  v-if="roomActions.length"
                  name="vac-slide-left"
                >
                  <div
                    v-if="roomMenuOpened === room.roomId"
                    v-click-outside="closeRoomMenu"
                    class="vac-menu-options"
                  >
                    <div class="vac-menu-list">
                      <div
                        v-for="action in roomActions"
                        :key="action.name"
                      >
                        <div
                          class="vac-menu-item"
                          @click.stop="roomActionHandler(action)"
                        >
                          <template v-if="action.name === 'toggleRead'">
                            <template v-if="isContactUnread(room)">
                              <img
                                :src="require('@/assets/img/whatsapp/comment_read_gray_alt.png')"
                                style="width: 16px !important;"
                                class="mr-2"
                              >
                            </template>
                            <template v-else>
                              <v-icon left>
                                fas fa-fas fa-comment
                              </v-icon>
                            </template>
                          </template>
                          <template v-else>
                            <v-icon left>
                              {{ action.icon }}
                            </v-icon>
                          </template>
                          <template v-if="action.name === 'toggleRead'">
                            <span v-if="isContactUnread(room)">
                              {{ $t('Marcar como leído') }}
                            </span>
                            <span v-else>
                              {{ $t('Marcar como no leído') }}
                            </span>
                          </template>
                          <span v-else>{{ action.title }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </slot>
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div>
      <span
        v-for="(field, index) in room.foundOnSearch"
        :key="index"
        class="search-tag mr-1"
      >
        {{ field }}
      </span>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '../../../components/SvgIcon/SvgIcon'
  import FormatMessage from '../../../components/FormatMessage/FormatMessage'

  import vClickOutside from 'v-click-outside'
  import typingText from '../../../utils/typing-text'
  import { isAudioFile } from '../../../utils/media-file'
  import { Contact } from '@/models/whatsapp/Contact'

  export default {
    name: 'RoomsContent',
    components: {
      SvgIcon,
      FormatMessage,
    },

    directives: {
      clickOutside: vClickOutside.directive,
    },

    props: {
      currentUserId: { type: [String, Number], required: true },
      room: { type: Object, required: true },
      textFormatting: { type: Object, required: true },
      linkOptions: { type: Object, required: true },
      textMessages: { type: Object, required: true },
      roomActions: { type: Array, required: true },
    },

    emits: ['room-action-handler'],

    data () {
      return {
        roomMenuOpened: null,
      }
    },

    computed: {
      isClosed () {
        return this.room.lastMessage.lastAction === 'close'
      },
      isEdited () {
        return this.room.lastMessage.lastAction === 'edit'
      },
      isOpen () {
        return this.room.lastMessage.lastAction === 'open'
      },
      isBlocked () {
        return this.room.lastMessage.lastAction === 'blocked'
      },
      isMessage () {
        return this.room.lastMessage.lastAction === 'message'
      },
      isChangeAgent () {
        return this.room.lastMessage.lastAction === 'change-agent'
      },
      getLastMessageNoNewLines () {
        return this.getLastMessage.replace(/\r/g, '').replace(/\n/g, ' ')
      },
      getLastMessage () {
        const isTyping = this.typingUsers
        if (isTyping) return isTyping

        const content = this.room.lastMessage.content

        if (this.room.users.length <= 2) {
          return content
        }

        const user = this.room.users.find(
          user => user._id === this.room.lastMessage.senderId,
        )

        if (this.room.lastMessage.username) {
          return `${this.room.lastMessage.username} - ${content}`
        } else if (!user || user._id === this.currentUserId) {
          return content
        }

        return `${user.username} - ${content}`
      },
      userStatus () {
        if (!this.room.users || this.room.users.length !== 2) return

        const user = this.room.users.find(u => u._id !== this.currentUserId)
        if (user && user.status) return user.status.state

        return null
      },
      typingUsers () {
        return typingText(this.room, this.currentUserId, this.textMessages)
      },
      isMessageCheckmarkVisible () {
        return (
          !this.typingUsers &&
          this.room.lastMessage &&
          !this.room.lastMessage.deleted &&
          this.room.lastMessage.senderId === this.currentUserId &&
          (this.room.lastMessage.saved ||
            this.room.lastMessage.distributed ||
            this.room.lastMessage.seen)
        )
      },
      formattedDuration () {
        const file = this.room.lastMessage?.files?.[0]
        if (file) {
          if (!file.duration) {
            return `${file.name}.${file.extension}`
          }

          let s = Math.floor(file.duration)
          return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
        }
        return ''
      },
      contactLetters () {
        let contact = this.room.users[1].contact
        if (!(contact instanceof Contact)) {
          contact = new Contact(contact)
        }
        return (contact.firstName?.charAt(0).toUpperCase() ?? '') + (contact.lastName?.charAt(0).toUpperCase() ?? '')
      },
      startsWithEmoji () {
        let contact = this.room.users[1].contact
        if (!(contact instanceof Contact)) {
          contact = new Contact(contact)
        }
        const emojiRegex = /^\p{Extended_Pictographic}/u
        return emojiRegex.test(contact.firstName)
      },
      isAudio () {
        return this.room.lastMessage.files
          ? isAudioFile(this.room.lastMessage.files[0])
          : false
      },
      roomName () {
        const contact = this.room.users[1].contact
        return this.room.roomName?.trim().length ? this.room.roomName : contact.msisdnClient
      },
      isClosedConversation () {
        const isClosedMessage = this.room.lastMessage?.status === 0
        const isClosedContact = this.room.users[1].contact.status === 0
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showcloseReasonTag = isClosedMessage
        return isClosedMessage || isClosedContact
      },
    },

    methods: {
      roomActionHandler (action) {
        this.closeRoomMenu()
        this.$emit('room-action-handler', { action, roomId: this.room.roomId })
      },
      closeRoomMenu () {
        // console.log('need to be closed...')
        this.roomMenuOpened = null
      },
      isContactUnread (room) {
        return !room.users[1].contact.read
      },
    },
  }
</script>

<style scoped>
.nametag {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-letter {
  display: block;
  color: white !important;
  font-size: 18px;
}
.vac-room-overcontainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
}
.search-tag {
  background-color: #ddd;
  color: black;
  font-size: 10px;
  padding: 0 3px;
  width:auto;
  border-radius: 5px;
  font-weight: 500;
}
</style>
