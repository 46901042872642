<template>
  <div>
    <div
      v-if="!['closed', 'opened', 'contact-edition', 'contact-note', 'contact-block', 'change-agent'].includes(message.type)"
      :id="message._id"
      ref="message"
      class="vac-message-wrapper"
    >
      <div
        v-if="showDate"
        class="vac-card-info vac-card-date"
      >
        {{ message.date }}
      </div>

      <div
        v-if="newMessage._id === message._id"
        class="vac-line-new"
      >
        {{ $t('Mensajes nuevos') }}
      </div>

      <div
        v-if="message.system"
        class="vac-card-info vac-card-system"
      >
        <slot :name="'message_' + message._id">
          <format-message
            :message-id="message._id"
            :content="simpleMessageParsed"
            :deleted="!!message.deleted"
            :users="roomUsers"
            :text-messages="textMessages"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            @open-user-tag="openUserTag"
          >
            <template
              v-for="(idx, name) in $slots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </format-message>
        </slot>
      </div>

      <div
        v-else
        class="vac-message-box"
        :class="{ 'vac-offset-current': message.senderId === currentUserId }"
        @click="selectMessage"
      >
        <slot :name="'message_' + message._id">
          <slot
            v-if="message.senderId !== currentUserId"
            :name="'message-avatar_' + message._id"
          >
            <div
              v-if="message.avatar"
              class="vac-avatar"
              :style="{ 'background-image': `url('${message.avatar}')` }"
            />
          </slot>
          <div
            v-if="hasSenderUserAvatar && !message.avatar"
            class="vac-avatar-offset"
          />
          <div
            class="vac-message-container vac-message-container-min-width vac-message-container-max-width"
            :class="{
              'vac-message-container-offset': messageOffset
            }"
          >
            <div
              class="vac-message-card"
              :class="{
                'vac-message-highlight': isMessageHover,
                'vac-message-current': message.senderId === currentUserId,
                'vac-message-deleted': message.deleted,
                'vac-item-clickable': messageSelectionEnabled,
                'vac-message-selected': isMessageSelected
              }"
              @mouseover="onHoverMessage"
              @mouseleave="onLeaveMessage"
            >
              <div
                v-if="showUsername"
                class="vac-text-username"
                :class="{
                  'vac-username-reply': !message.deleted && message.replyMessage
                }"
              >
                <span>{{ message.username }}</span>
              </div>

              <message-reply
                v-if="!message.deleted && message.replyMessage"
                :message="message"
                :room-users="roomUsers"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
              >
                <template
                  v-for="(i, name) in $slots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </message-reply>

              <!-- unsupported message -->
              <div v-if="message.type === 'unsupported'">
                <v-icon
                  size="15"
                  style="margin-top: -3px;"
                >
                  fa fa-warning
                </v-icon>
                <span>
                  {{ message.reason }}
                </span>
              </div>

              <!-- location message -->
              <div v-if="message.type === 'location'">
                <div class="vac-image-link-container">
                  <a
                    :href="'https://maps.google.com/?ll='+ message.latitude + ',' + message.longitude"
                    target="_blank"
                  >
                    <div
                      class="vac-image-link text-center"
                      :style="{
                        'background-color': '#fdfdfd',
                        height: '120px',
                        width: '170px'
                      }"
                    >
                      <img
                        src="@/assets/img/whatsapp/location.png"
                        style="max-width: 182px; padding-top: 0 !important"
                        class="pt-5"
                      >
                    </div>
                  </a>
                </div>
                <p
                  style="max-width:150px;"
                  class="py-0 my-0"
                >
                  {{ message.name }}
                </p>
                <p style="max-width:150px;">
                  {{ message.address }}
                </p>
              </div>

              <!-- template message -->
              <div v-if="message.type === 'template'">
                <div v-if="templateHasHeader">
                  <!-- image -->
                  <div v-if="message.template.head_board_option == 1">
                    <v-img
                      width="235px"
                      :src="urlOrSubstitution(message)"
                    />
                  </div>
                  <!-- text -->
                  <div v-if="message.template.head_board_option == 2">
                    <b>
                      <p v-html="headerPreview" />
                    </b>
                  </div>
                  <!-- video -->
                  <div v-if="message.template.head_board_option == 3">
                    <video
                      width="350px"
                      controls
                    >
                      <source :src="urlOrSubstitution(message)">
                    </video>
                  </div>
                  <!-- attachment -->
                  <div
                    v-if="message.template.head_board_option == 4"
                    class="white text-center"
                    style="width: 100%"
                  >
                    <a
                      :href="urlOrSubstitution(message)"
                      download
                    >
                      <img
                        class="mt-10"
                        src="@/assets/img/whatsapp/doc-icon.png"
                        style="max-width: 45px;"
                      >
                      <p class="grey--text pt-1 pb-8 text-decoration-underline">
                        {{ $t('Ver documento') }}
                      </p>
                    </a>
                  </div>
                  <!-- localization -->
                  <div
                    v-if="message.template.head_board_option == 5"
                  >
                    <div class="vac-image-link-container">
                      <a
                        :href="'https://maps.google.com/?ll='+ message.latitude + ',' + message.longitude"
                        target="_blank"
                      >
                        <div
                          class="vac-image-link text-center"
                          :style="{
                            'background-color': '#fdfdfd',
                            height: '120px',
                            width: '100%'
                          }"
                        >
                          <img
                            src="@/assets/img/whatsapp/localization.png"
                            style="max-width: 45px;"
                            class="pt-5"
                          >
                        </div>
                      </a>
                    </div>
                    <p
                      style="max-width:150px;"
                      class="py-0 my-0"
                    >
                      {{ message.name }}
                    </p>
                    <p style="max-width:150px;">
                      {{ message.address }}
                    </p>
                  </div>
                </div>
                <p v-html="messagePreview" />
                <span
                  v-if="message.template.footer_page && message.template.footer_page !== ''"
                  class="footer-text"
                >
                  {{ message.template.footer_page }}
                </span>
              </div>

              <!-- interactive message -->
              <div v-if="message.type === 'interactive'">
                <div v-if="interactiveHasHeader">
                  <!-- image -->
                  <div v-if="isInteractiveHeaderImage">
                    <v-img
                      width="235px"
                      :src="urlOrSubstitution(message)"
                    />
                  </div>
                  <!-- text -->
                  <div v-if="isInteractiveHeaderText">
                    <b>
                      <p v-html="interactiveTextHeaderPreview" />
                    </b>
                  </div>
                  <!-- video -->
                  <div v-if="isInteractiveHeaderVideo">
                    <video
                      width="350px"
                      controls
                    >
                      <source :src="urlOrSubstitution(message)">
                    </video>
                  </div>
                  <!-- attachment -->
                  <div
                    v-if="isInteractiveHeaderDocument"
                    class="white text-center"
                    style="width: 100%"
                  >
                    <a
                      :href="urlOrSubstitution(message)"
                      download
                    >
                      <img
                        class="mt-10"
                        src="@/assets/img/whatsapp/doc-icon.png"
                        style="max-width: 45px;"
                      >
                      <p class="grey--text pt-1 pb-8 text-decoration-underline">
                        {{ $t('Ver documento') }}
                      </p>
                    </a>
                  </div>
                </div>
                <p v-html="interactiveMessagePreview" />
                <span
                  v-if="interactiveHasFooter"
                  class="footer-text"
                >
                  {{ interactiveFooterPreview }}
                </span>
              </div>

              <format-message
                v-else-if="!!message.deleted || !message.files || !message.files.length"
                :message-id="message._id"
                :content="simpleMessageParsed"
                :deleted="!!message.deleted"
                :users="roomUsers"
                :text-formatting="textFormatting"
                :text-messages="textMessages"
                :link-options="linkOptions"
                @open-user-tag="openUserTag"
              >
                <template
                  v-for="(idx, name) in $slots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </format-message>

              <!-- files message -->
              <message-files
                v-else-if="!isAudio || message.files.length > 1"
                :current-user-id="currentUserId"
                :message="message"
                :room-users="roomUsers"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
                :message-selection-enabled="messageSelectionEnabled"
                @open-file="openFile"
                @open-user-tag="openUserTag"
              >
                <template
                  v-for="(i, name) in $slots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </message-files>

              <!-- audio message -->
              <template v-else>
                <audio-player
                  :message-id="message._id"
                  :src="message.files[0].url"
                  :message-selection-enabled="messageSelectionEnabled"
                  @update-progress-time="progressTime = $event"
                  @hover-audio-progress="hoverAudioProgress = $event"
                >
                  <template
                    v-for="(i, name) in $slots"
                    #[name]="data"
                  >
                    <slot
                      :name="name"
                      v-bind="data"
                    />
                  </template>
                </audio-player>

                <div
                  v-if="!message.deleted"
                  class="vac-progress-time"
                >
                  {{ progressTime }}
                </div>
              </template>

              <div class="vac-text-timestamp">
                <div
                  v-if="message.edited && !message.deleted"
                  class="vac-icon-edited"
                >
                  <slot :name="'pencil-icon_' + message._id">
                    <svg-icon name="pencil" />
                  </slot>
                </div>
                <span>{{ message.timestamp }}</span>
                <span v-if="isCheckmarkVisible">
                  <slot :name="'checkmark-icon_' + message._id">
                    <svg-icon
                      :name="
                        message.distributed ? 'double-checkmark' : 'checkmark'
                      "
                      :param="message.seen ? 'seen' : ''"
                      class="vac-icon-check"
                    />
                  </slot>
                </span>
              </div>

              <message-actions
                :current-user-id="currentUserId"
                :message="message"
                :message-actions="messageActions"
                :show-reaction-emojis="showReactionEmojis"
                :message-hover="messageHover"
                :hover-message-id="hoverMessageId"
                :hover-audio-progress="hoverAudioProgress"
                :emoji-data-source="emojiDataSource"
                @update-message-hover="messageHover = $event"
                @update-options-opened="optionsOpened = $event"
                @update-emoji-opened="emojiOpened = $event"
                @message-action-handler="messageActionHandler"
                @send-message-reaction="sendMessageReaction"
              >
                <template
                  v-for="(i, name) in $slots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </message-actions>
            </div>

            <message-reactions
              :current-user-id="currentUserId"
              :message="message"
              @send-message-reaction="sendMessageReaction"
            />

            <!-- template buttons -->
            <div v-if="message.type === 'template' && hasOptions">
              <!-- call to action buttons -->
              <div>
                <div
                  v-for="(response, responseIndex) in allResponses.slice(0, 2)"
                  :key="responseIndex"
                  class="call-action"
                >
                  <template v-if="response.responseOption === ResponseOptionType.CALL_TO_ACTION">
                    <!-- Button URL -->
                    <a
                      v-if="response.type === CallToActionType.EXTERNAL_LINK"
                      :href="response.url"
                      target="_blank"
                      class="call-action-link"
                    >
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        fa-solid fa-external-link-alt
                      </v-icon>  {{ response.text }}
                    </a>
                    <!-- Button phone -->
                    <a
                      v-if="response.type === CallToActionType.EXTERNAL_PHONE"
                      class="call-action-link"
                      :href="'tel:' + response.phonePrefix + '' + response.phone"
                    >
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        fa-solid fa-phone
                      </v-icon>  {{ response.text }}
                    </a>
                    <!-- Button Copy Code -->
                    <span
                      v-if="response.type === CallToActionType.COPY_CODE"
                      class="call-action-link"
                    >
                      <!-- @click="copyToClipboard(response.code)" -->
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        far fa-solid fa-clone
                      </v-icon>  {{ response.text }}
                    </span>
                  </template>
                  <template v-if="response.responseOption === ResponseOptionType.AUTOMATIC_RESPONSE">
                    <span
                      v-if="response.type === AutomaticResponseType.NORMAL"
                      class="call-action-link"
                    >
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        fas fa-reply
                      </v-icon>  {{ response.text }}
                    </span>

                    <span
                      v-if="response.type === AutomaticResponseType.DISABLE_MARKETING"
                      class="call-action-link"
                    >
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        fas fa-reply
                      </v-icon>  {{ response.textDisableMarketing }}
                    </span>
                  </template>
                </div>

                <div v-if="responseSeeAll">
                  <div>
                    <button
                      class="call-action call-action-link"
                      @click="toggleNavigationDrawerOption()"
                    >
                      <v-icon
                        style=" font-size: 0.85em;"
                        color="#2487d2"
                      >
                        fa-solid fas fa-list
                      </v-icon>  {{ $t('Ver opciones') }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- All responses -->
              <div
                v-show="navigationDrawerOption.show"
                class="slider-navigation-drawer-content inner-box"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      <v-row style="font-size: 1.25em;">
                        <v-col style="text-align:center">
                          <button
                            style="position: absolute; left: 10px;"
                            @click="toggleNavigationDrawerOption()"
                          >
                            <v-icon
                              color="#2487d2"
                            >
                              fa-solid fas fa-close
                            </v-icon>
                          </button>
                          <span>
                            {{ $t('Todas las opciones') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider />

                <div v-if="hasOptions">
                  <div
                    v-for="(response, responsesIndex) in allResponses"
                    :key="responsesIndex"
                    class="call-action"
                  >
                    <div v-if="response.responseOption === ResponseOptionType.CALL_TO_ACTION">
                      <a
                        v-if="response.type === CallToActionType.EXTERNAL_LINK"
                        class="call-action-link"
                        :href="response.url"
                        target="_blank"
                      >
                        <v-icon
                          style=" font-size: 1em;"
                          color="#2487d2"
                        >
                          fas fa-external-link-alt
                        </v-icon>  {{ response.text }}
                      </a>

                      <a
                        v-if="response.type === CallToActionType.EXTERNAL_PHONE"
                        class="call-action-link"
                        :href="'tel:' + response.phonePrefix + '' + response.phone"
                      >
                        <v-icon
                          style=" font-size: 1em;"
                          color="#2487d2"
                        >
                          fa-solid fa-phone
                        </v-icon>  {{ response.text }}
                      </a>

                      <span
                        v-if="response.type === CallToActionType.COPY_CODE"
                        class="call-action-link"
                      >
                        <!-- @click="copyToClipboard(response.code)" -->
                        <v-icon
                          style="font-size: 1em;"
                          color="#2487d2"
                        >
                          far fa-solid fa-clone
                        </v-icon>  {{ response.text }}
                      </span>
                    </div>

                    <div v-if="response.responseOption === ResponseOptionType.AUTOMATIC_RESPONSE">
                      <span
                        v-if="response.type === AutomaticResponseType.NORMAL"
                        class="call-action-link"
                      >
                        <v-icon
                          style=" font-size: 1em;"
                          color="#2487d2"
                        >
                          fas fa-reply
                        </v-icon>  {{ response.text }}
                      </span>

                      <span
                        v-if="response.type === AutomaticResponseType.DISABLE_MARKETING"
                        class="call-action-link"
                      >
                        <v-icon
                          style=" font-size: 1em;"
                          color="#2487d2"
                        >
                          fas fa-reply
                        </v-icon>  {{ response.textDisableMarketing }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- interactive options -->
            <div v-if="message.type === 'interactive'">
              <template v-if="isInteractiveTypeButton">
                <div
                  v-for="(button, buttonIndex) in message.interactiveMessage.buttons"
                  :key="buttonIndex"
                  class="call-action"
                >
                  <span class="call-action-link">
                    {{ button.title }}
                  </span>
                </div>
              </template>
              <template v-if="isInteractiveTypeList">
                <button
                  class="call-action call-action-link"
                  @click="toggleNavigationDrawerInteractiveList()"
                >
                  <v-icon
                    style=" font-size: 0.85em;"
                    color="#2487d2"
                    class="mr-2 mt-0"
                  >
                    fa-solid fa-list
                  </v-icon>{{ message.interactiveMessage.button_list }}
                </button>
              </template>
              <template v-if="isInteractiveTypeLocation">
                <div class="call-action">
                  <span class="call-action-link">
                    <v-icon
                      style=" font-size: 0.85em;"
                      color="#2487d2"
                      class="mr-2"
                    >
                      fa-solid fa-map-pin
                    </v-icon>{{ $t('Enviar ubicación') }}
                  </span>
                </div>
              </template>
            </div>

            <!-- interactive list all options -->
            <div
              v-if="isInteractiveTypeList"
              v-show="navigationDrawerInteractiveList.show"
              transition="fade-transition"
              class="navigation-drawer-content inner-box"
              style="top: -156px !important; height: 250px !important;"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    <v-row style="font-size: 1.25em;">
                      <v-col cols="3">
                        <button
                          @click="toggleNavigationDrawerInteractiveList()"
                        >
                          <v-icon
                            color="#2487d2"
                          >
                            fa-solid fas fa-close
                          </v-icon>
                        </button>
                      </v-col>
                      <v-col style="text-align:left">
                        {{ message.interactiveMessage.button_list }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <div>
                <div
                  v-for="(section, sectionIndex) in message.interactiveMessage.sections"
                  :key="sectionIndex"
                >
                  <div class="mt-5">
                    <p
                      v-if="section.title != ''"
                      class="mb-2 ms-2"
                      style="color: blue;"
                    >
                      {{ section.title }}
                    </p>
                    <v-row
                      v-for="row in section.rows"
                      :key="row.row_id"
                      class="ms-2"
                      style="width: 96% !important"
                    >
                      <v-col
                        class="py-1"
                        cols="7"
                      >
                        <div
                          class="font-weight-bold"
                        >
                          {{ row.title }}
                        </div>
                        <div
                          v-if="row.description != ''"
                          class="text-caption"
                        >
                          {{ row.description }}
                        </div>
                      </v-col>
                      <v-col
                        cols="2"
                        class="px-0"
                      >
                        <v-icon>
                          far fa-circle
                        </v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>

            <span
              v-if="message.senderId === currentUserId"
              class="footer-text float-left mt-2 mb-2"
              style="font-size: 0.8em;"
            >
              {{ $t('Realizado por') }}<span v-if="message.agent"> {{ $t('chatbot') }}</span>:
              <span v-if="message.agent">{{ message.agent }}</span>
              <span v-else>{{ user.username }}</span>
            </span>
          </div>
          <slot :name="'message-failure_' + message._id">
            <div
              v-if="message.failure && message.senderId === currentUserId"
              class="vac-failure-container vac-svg-button"
              :class="{
                'vac-failure-container-avatar':
                  message.avatar && message.senderId === currentUserId
              }"
              @click="$emit('open-failed-message', { message })"
            >
              <div class="vac-failure-text">
                !
              </div>
            </div>
          </slot>
          <slot
            v-if="message.senderId === currentUserId"
            :name="'message-avatar_' + message._id"
          >
            <div
              v-if="message.avatar"
              class="vac-avatar vac-avatar-current"
              :style="{ 'background-image': `url('${message.avatar}')` }"
            />
          </slot>
          <div
            v-if="hasCurrentUserAvatar && !message.avatar"
            class="vac-avatar-current-offset"
          />
        </slot>
      </div>
    </div>
    <div
      v-else
      class="vac-message-wrapper mb-5"
    >
      <div
        class="vac-message-box text-center"
        style="display: block; margin-left: auto; margin-right: auto;"
      >
        <div class="vac-message-container vac-message-container-max-width">
          <div class="vac-message-card">
            <div class="vac-format-message-wrapper">
              <div class="">
                <div class="vac-format-container">
                  <span
                    v-if="message.type === 'closed'"
                    class=""
                  >
                    <v-icon
                      size="15"
                      class="float-left"
                    >
                      fas fa-box-archive
                    </v-icon>
                    <span>
                      {{ $t('Cerrado') }}: {{ simpleMessageParsed }}
                    </span>
                    <span class="float-right">
                      {{ message.timestamp }}
                    </span>
                  </span>
                  <span
                    v-if="message.type === 'opened'"
                    class=""
                  >
                    <v-icon
                      size="15"
                      class="float-left"
                    >
                      fas fa-box-archive
                    </v-icon>
                    <span>
                      {{ simpleMessageParsed }}
                    </span>
                    <span class="float-right">
                      {{ message.timestamp }}
                    </span>
                  </span>
                  <span
                    v-if="message.type === 'contact-edition'"
                    class=""
                  >
                    <v-icon
                      size="15"
                      class="float-left"
                    >
                      fas fa-pencil
                    </v-icon>
                    <span>
                      {{ $t('Editado') }} ({{ simpleMessageParsed }})
                    </span>
                    <span class="float-right">
                      {{ message.timestamp }}
                    </span>
                  </span>

                  <span
                    v-if="message.type === 'contact-note'"
                    class=""
                  >
                    <v-icon
                      size="15"
                      class="float-left"
                      color="blue"
                    >
                      fas fa-file-medical
                    </v-icon>
                    <span>
                      <b>{{ $t('Nota') }}:</b> {{ simpleMessageParsed }}
                    </span>
                    <span class="float-right">
                      {{ message.timestamp }}
                    </span>
                  </span>

                  <span
                    v-if="message.type === 'contact-block'"
                  >
                    <v-icon
                      size="15"
                      class="float-left"
                    >
                      {{ message.status === 1 ? 'fas fa-user-slash' : 'fas fa-user' }}
                    </v-icon>
                    <span v-if="message.status === 1">
                      {{ $t('Bloqueado') }}
                    </span>
                    <span v-if="message.status === 0">
                      {{ $t('Desbloqueado') }}
                    </span>
                    <span>
                      {{ $t('de') }}:
                    </span>
                    <span v-if="message.blockType === 1">
                      {{ $t('Utilidad') }}
                    </span>
                    <span v-if="message.blockType === 2">
                      {{ $t('Marketing') }}
                    </span>
                    <span v-if="message.blockType === 3">
                      {{ $t('Autenticación') }}
                    </span>
                    <span v-if="message.blockType === 4">
                      {{ $t('Inbox') }}
                    </span>
                    <span class="float-right">
                      {{ message.timestamp }}
                    </span>
                  </span>

                  <span
                    v-if="message.type === 'change-agent'"
                    class=""
                  >
                    <v-icon
                      v-if="message.agentTypeId === 0"
                      class="theme-primary-color float-left"
                      size="15"
                    >
                      <v-icon
                        v-if="message.agentTypeId === 0"
                        class="float-left"
                        color="blue darken-1"
                        size="15"
                      >
                        fas fa-user
                      </v-icon>
                      <v-img
                        v-else
                        :src="require('@/assets/img/nia/nia.png')"
                        max-width="20px"
                        class="mr-2 float-left"
                      />
                      <span>
                        {{ $t('Cambio de agente') }}: {{ message.agentName }}
                      </span>
                      <span class="float-right">
                        {{ message.timestamp }}
                      </span>
                    </v-icon></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '../../../components/SvgIcon/SvgIcon'
  import FormatMessage from '../../../components/FormatMessage/FormatMessage'

  import MessageReply from './MessageReply/MessageReply'
  import MessageFiles from './MessageFiles/MessageFiles'
  import MessageActions from './MessageActions/MessageActions'
  import MessageReactions from './MessageReactions/MessageReactions'
  import AudioPlayer from './AudioPlayer/AudioPlayer'

  import { messagesValidation } from '../../../utils/data-validation'
  import { isAudioFile } from '../../../utils/media-file'
  import { TextFormatParser } from '@/classes/TextFormatParser.js'
  import HeadboardType from '@/models/whatsapp-editor/HeadboardType'
  import { CALL_TO_ACTION_TYPE } from '@/models/whatsapp-editor/enums/CallToActionType'
  import { RESPONSE_OPTION_TYPE } from '@/models/whatsapp-editor/enums/ResponseOptionType'
  import AutomaticResponseType from '@/models/whatsapp-editor/AutomaticResponseType'

  export default {
    name: 'RoomMessage',
    components: {
      SvgIcon,
      FormatMessage,
      AudioPlayer,
      MessageReply,
      MessageFiles,
      MessageActions,
      MessageReactions,
    },

    props: {
      currentUserId: { type: [String, Number], required: true },
      textMessages: { type: Object, required: true },
      index: { type: Number, required: true },
      message: { type: Object, required: true },
      messages: { type: Array, required: true },
      editedMessageId: { type: [String, Number], default: null },
      roomUsers: { type: Array, default: () => [] },
      messageActions: { type: Array, required: true },
      newMessages: { type: Array, default: () => [] },
      showReactionEmojis: { type: Boolean, required: true },
      showNewMessagesDivider: { type: Boolean, required: true },
      textFormatting: { type: Object, required: true },
      linkOptions: { type: Object, required: true },
      usernameOptions: { type: Object, required: true },
      messageSelectionEnabled: { type: Boolean, required: true },
      selectedMessages: { type: Array, default: () => [] },
      emojiDataSource: { type: String, default: undefined },
    },

    emits: [
      'message-added',
      'open-file',
      'open-user-tag',
      'open-failed-message',
      'message-action-handler',
      'send-message-reaction',
      'select-message',
      'unselect-message',
    ],

    data () {
      return {
        hoverMessageId: null,
        messageHover: false,
        optionsOpened: false,
        emojiOpened: false,
        newMessage: {},
        progressTime: '- : -',
        user: {},
        hoverAudioProgress: false,
        htmlLabel: {
          strong: {
            format: '*',
            init: 1,
            finish: 2,
            start: '<strong>',
            end: '</strong>',
          },
          em: {
            format: '_',
            init: 1,
            finish: 2,
            start: '<em>',
            end: '</em>',
          },
          strike: {
            format: '~',
            init: 1,
            finish: 2,
            start: '<strike>',
            end: '</strike>',
          },
          code: {
            format: '`',
            init: 1,
            finish: 3,
            row: 1,
            start: '<code>',
            end: '</code>',
          },
        },
        HeadboardTypes: HeadboardType.headboardTypes,
        CallToActionType: CALL_TO_ACTION_TYPE,
        AutomaticResponseType: AutomaticResponseType.responseTypes,
        ResponseOptionType: RESPONSE_OPTION_TYPE,
        navigationDrawerOption: {
          show: false,
        },
        navigationDrawerInteractiveList: {
          show: false,
        },
        textFormatParser: new TextFormatParser(),
      }
    },

    computed: {
      responseSeeAll: function () {
        return this.allResponses.length > 2
      },
      allResponses () {
        return [...this.callToActionItems, ...this.automaticResponseItems]
      },
      callToActionItems () {
        return this.message.template?.call_to_action_responses
          ? JSON.parse(this.message.template.call_to_action_responses)
          : []
      },
      automaticResponseItems () {
        return this.message.template?.automatic_responses
          ? JSON.parse(this.message.template.automatic_responses)
          : []
      },
      hasOptions () {
        return [this.HeadboardTypes.OPTION_TEXT, this.HeadboardTypes.OPTION_HEADER_TEXT].includes(this.message.template.head_board_type)
      },
      showUsername () {
        if (
          !this.usernameOptions.currentUser &&
          this.message.senderId === this.currentUserId
        ) {
          return false
        } else {
          return this.roomUsers.length >= this.usernameOptions.minUsers
        }
      },
      showDate () {
        return (
          this.index > 0 &&
          this.message.date !== this.messages[this.index - 1].date
        )
      },
      messageOffset () {
        return (
          this.index > 0 &&
          this.message.senderId !== this.messages[this.index - 1].senderId
        )
      },
      isMessageHover () {
        return (
          this.editedMessageId === this.message._id ||
          this.hoverMessageId === this.message._id
        )
      },
      isAudio () {
        return this.message.files?.some(file => isAudioFile(file))
      },
      isCheckmarkVisible () {
        return (
          this.message.senderId === this.currentUserId &&
          !this.message.deleted &&
          (this.message.saved || this.message.distributed || this.message.seen)
        )
      },
      hasCurrentUserAvatar () {
        return this.messages.some(
          message => message.senderId === this.currentUserId && message.avatar,
        )
      },
      hasSenderUserAvatar () {
        return this.messages.some(
          message => message.senderId !== this.currentUserId && message.avatar,
        )
      },
      isMessageSelected () {
        return (
          this.messageSelectionEnabled &&
          !!this.selectedMessages.find(
            message => message._id === this.message._id,
          )
        )
      },
      templateHasHeader () {
        return [
          HeadboardType.headboardTypes.HEADER_TEXT,
          HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
          HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
          HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
        ].includes(this.message.template.head_board_type)
      },
      messagePreview: function () {
        return this.textFormatParser.formatHtmlMessage(this.message.susbtitutedMessage.toString())
      },
      headerPreview: function () {
        return this.textFormatParser.formatHtmlMessage(this.message.headerSusbtitutedMessage.toString())
      },
      simpleMessageParsed () {
        return this.textFormatParser.formatHtmlMessageFixHtml(this.message.content)
      },
      interactiveHasHeader () {
        return this.message.interactiveMessage?.header.type !== null
      },
      isInteractiveHeaderImage () {
        return this.message.interactiveMessage?.header.type === 'image'
      },
      isInteractiveHeaderText () {
        return this.message.interactiveMessage?.header.type === 'text'
      },
      isInteractiveHeaderVideo () {
        return this.message.interactiveMessage?.header.type === 'video'
      },
      isInteractiveHeaderDocument () {
        return this.message.interactiveMessage?.header.type === 'document'
      },
      interactiveMessagePreview: function () {
        return this.formatHtmlMessage(this.message.interactiveMessage.body)
      },
      interactiveTextHeaderPreview: function () {
        return this.formatHtmlMessage(this.message.interactiveMessage.header.text)
      },
      interactiveFooterPreview: function () {
        return this.message.interactiveMessage?.footer
      },
      interactiveHasFooter () {
        return this.message.interactiveMessage?.footer !== null
      },
      isInteractiveTypeButton () {
        return this.message.interactiveMessage?.type_interactive === 'button'
      },
      isInteractiveTypeList () {
        return this.message.interactiveMessage?.type_interactive === 'list'
      },
      isInteractiveTypeLocation () {
        return this.message.interactiveMessage?.type_interactive === 'location_request_message'
      },
    },

    watch: {
      newMessages: {
        immediate: true,
        deep: true,
        handler (val) {
          if (!val.length || !this.showNewMessagesDivider) {
            this.newMessage = {}
            return
          }

          this.newMessage = val.reduce((res, obj) =>
            obj.index < res.index ? obj : res,
          )
        },
      },
      messageSelectionEnabled () {
        this.resetMessageHover()
      },
    },

    mounted () {
      messagesValidation(this.message)

      setTimeout(() => {
        this.$emit('message-added', {
          message: this.message,
          index: this.index,
          ref: this.$refs.message,
        })
      }, 500)
      this.user = JSON.parse(localStorage.getItem('user'))
    },

    methods: {
      copyToClipboard (text) {
        navigator.clipboard.writeText(text)
      },
      toggleNavigationDrawerOption () {
        this.navigationDrawerOption.show = !this.navigationDrawerOption.show
      },
      toggleNavigationDrawerInteractiveList () {
        this.navigationDrawerInteractiveList.show = !this.navigationDrawerInteractiveList.show
      },
      formatHtmlMessage (message) {
        if (!message) return message
        for (let i = 0; i < message.length; i++) {
          switch (message[i]) {
            case this.htmlLabel.strong.format:
              message = this.setHtmlMessage('strong', message, i)
              break
            case this.htmlLabel.em.format:
              message = this.setHtmlMessage('em', message, i)
              break
            case this.htmlLabel.strike.format:
              message = this.setHtmlMessage('strike', message, i)
              break
            case this.htmlLabel.code.format:
              message = this.setHtmlMessageCode('code', message, i)
              break
            default:
              break
          }
        }

        message = this.replaceEscapedQuotes(message)
        return message
      },

      replaceEscapedQuotes (message) {
        const regExp = /\\"/g
        message = message.replace(regExp, '"')
        return message
      },
      setHtmlMessage (type, message, index) {
        if (this.htmlLabel[type].init === this.htmlLabel[type].finish) {
          this.htmlLabel[type].init = 1
          message = this.setCharAt(message, index, this.htmlLabel[type].end)
        } else {
          this.htmlLabel[type].init++
          message = this.setCharAt(message, index, this.htmlLabel[type].start)
        }

        return message
      },
      setHtmlMessageCode (type, message, index) {
        if (this.htmlLabel[type].init === this.htmlLabel[type].finish && this.htmlLabel[type].row === 1) {
          this.htmlLabel[type].init = 1
          this.htmlLabel[type].row = 2
          message = this.setCharAtCode(message, index, this.htmlLabel[type].start)
        } else if (this.htmlLabel[type].init === this.htmlLabel[type].finish && this.htmlLabel[type].row === 2) {
          this.htmlLabel[type].init = 1
          this.htmlLabel[type].row = 1
          message = this.setCharAtCode(message, index, this.htmlLabel[type].end)
        } else {
          this.htmlLabel[type].init++
        }

        return message
      },
      setCharAt (str, index, chr) {
        if (index > str.length - 1) return str
        return str.substring(0, index) + chr + str.substring(index + 1)
      },
      setCharAtCode (str, index, chr) {
        if (index > str.length - 1) return str
        return str.substring(0, index - 2) + chr + str.substring(index + 1)
      },
      onHoverMessage () {
        if (!this.messageSelectionEnabled) {
          this.messageHover = true
          if (this.canEditMessage()) this.hoverMessageId = this.message._id
        }
      },
      canEditMessage () {
        return !this.message.deleted
      },
      onLeaveMessage () {
        if (!this.messageSelectionEnabled) {
          if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false
          this.hoverMessageId = null
        }
      },
      resetMessageHover () {
        this.messageHover = false
        this.hoverMessageId = null
      },
      openFile (file) {
        this.$emit('open-file', { message: this.message, file: file })
      },
      openUserTag (user) {
        this.$emit('open-user-tag', { user })
      },
      messageActionHandler (action) {
        this.resetMessageHover()

        setTimeout(() => {
          this.$emit('message-action-handler', { action, message: this.message })
        }, 300)
      },
      sendMessageReaction ({ emoji, reaction }) {
        this.$emit('send-message-reaction', {
          messageId: this.message._id,
          reaction: emoji,
          remove: reaction && reaction.indexOf(this.currentUserId) !== -1,
        })
        this.messageHover = false
      },
      selectMessage () {
        if (this.messageSelectionEnabled) {
          if (this.isMessageSelected) {
            this.$emit('unselect-message', this.message._id)
          } else {
            this.$emit('select-message', this.message)
          }
        }
      },
      urlOrSubstitution (message) {
        return message.substitutions?.header?.url || message.template?.head_board.url || message.interactiveMessage?.header?.url
      },
    },
  }
</script>
<style scoped>
.slider-navigation-drawer-content {
  background-color: #fff;
  transition: all .3s ease-out;
  bottom: 32px !important;
  left: 10px !important;
  position: absolute !important;
  width: 97% !important;
  border-radius: 0.5rem !important;
  z-index: 100;
}

.slider-navigation-drawer-content {
  background-color: #fff;
  transition: all .3s ease-out;
  bottom: 32px !important;
  left: 10px !important;
  position: absolute !important;
  width: 97% !important;
  border-radius: 0.5rem !important;
  z-index: 100;
}

.interactive-list {
  top: -103px !important;
  height: 200px !important;
  border: solid 1px #DADDE1;
}

</style>
