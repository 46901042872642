import { render, staticRenderFns } from "./RoomContent.vue?vue&type=template&id=7e6ea0e4&scoped=true&"
import script from "./RoomContent.vue?vue&type=script&lang=js&"
export * from "./RoomContent.vue?vue&type=script&lang=js&"
import style0 from "./RoomContent.vue?vue&type=style&index=0&id=7e6ea0e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e6ea0e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VIcon,VImg,VSheet})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
