import axios from 'axios'
import getEnv from '../util/env'

let URL = ''

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export class WhatsappWebhook {
  provider = null

  constructor () {
    URL = getEnv('VUE_APP_WEBHOOK_URL')
  }

  setProvider (provider) {
    this.provider = provider === 'cloud' ? 'meta' : provider
  }

  async get (path, params = {}) {
    const response = await axios.get(URL + path, {
      params: params,
    })

    return response.data
  }

  async postResult (path, data) {
    const response = await axios.post(URL + path, data)

    return response
  }

  post (path, data) {
    return axios.post(URL + path, data)
  }
}
